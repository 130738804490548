// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();

// Place any jQuery/helper plugins in here.


// @codekit-prepend quiet "jQuery";
// @codekit-prepend quiet "jquery-migrate";

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {

	function classMobile() {

		var size = window.getComputedStyle(document.body, ':before').getPropertyValue('content');

		size = size.replace(/"/g, '');

		if (size == 'mobile') {
			$('body').addClass('mobile');
			$('body').removeClass('screen');
		} else if (size == 'screen') {
			$('body').addClass('screen');
			$('body').removeClass('mobile');
		} else {
			$('body').removeClass('mobile');
			$('body').removeClass('screen');
		}
	}

	classMobile();




	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
		$('header .nav-header').slideToggle();
		// $('header').toggleClass('menu-active');

		// console.log('click burger');
	});

	function headerReset() {
		$('.mobile header .nav-header').hide();
		$('.screen header .nav.header').show();
	}

	headerReset();


	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			// burger navigation
			$('body').removeClass('bodyfix');
			$('.hamburg er').removeClass('is-active');
			$('header nav').removeClass('is-active');

			// console.log('esc close menu');
		}
	});



	/* $("video:is()").each(function() {
	$(this).play();
	$(this).muted = true;
}); */

	//$('.intro) video:is()').play();
	var intro_video = $('#intro-video').get(0);
	if (intro_video) {
		intro_video.play();
	}

	// in view function ----------------------------------------------------------
	function isScrolledIntoView(elem) {
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();

		var elemTop = $(elem).offset().top;
		var elemBottom = elemTop + $(elem).height();

		return elemBottom <= docViewBottom && elemTop >= docViewTop;
	}

	// animated headline lines 
	$(window).on('scroll', function() {
		$('.headline-wrapper, .image-video-groupe figure').each(function() {
			$(this).addClass('in-view');
			if (isScrolledIntoView($(this))) {
				var video = $(this).find('video').get(0);
				if (video) {
					video.play();
				}
			} else {
				$(this).removeClass('in-view');
				var video = $(this).find('video').get(0);
				if (video) {
					video.pause();
				}
			}
		});
	});



	//-------------------------------------------------------------------------------------------------------------------------
	// sticky navigation

	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('header nav').outerHeight();
	var topFromViewport = 20 // vh / %

	$(window).on('scroll', function(event) {
		didScroll = true;
	});

	var is_home = false;
	var header_height = $('header').outerHeight();

	// console.log('header_height ' + header_height);

	if (typeof comment === 'undefined') {
		var header_height = 1;
	} else if (comment === null) {
		var header_height = 1;
	}

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 300);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) return;

		// If scrolled down and past the navbar, add class .nav-up.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			$('header')
				.removeClass('nav-up')
				.addClass('nav-down');
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				$('header')
					.removeClass('nav-down')
					.addClass('nav-up');

				// console.log(st);
				// hide the sticky nav before reaching the pagehead
				if (st < topFromViewport * $(window).height() / 100) {
					$('header').removeClass('nav-down').removeClass('nav-up');
					$('header').addClass('nav-top');
				} else {
					$('header').removeClass('nav-top');
				}
			}
		}

		lastScrollTop = st;
	}

	// image scroll animations ----------------------------------------------------



	function shrinkImage(elem) {
		const shift = 120;
		var elmHeight = elem.height();
		elem.closest('.image-video-groupe figure.translate-shift, .image figure.translate-shift').css('height', elmHeight - shift);
	}

	$('.image-video-groupe figure.translate-shift img, .image figure.translate-shift img').on('load', function() {
		shrinkImage($(this));
	});

	function isElementPartiallyInViewport(el) {
		var rect = el.getBoundingClientRect();
		var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
		var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
		var vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
		var horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
		return (vertInView && horInView);
	}

	function translateImages(elem, shift, speedFactor, scrollChange) {

		var transform = elem.find('.image-wrapper').css('transform');
		if (transform) {
			var matrix = transform.replace(/[^0-9\-.,]/g, '').split(',');
			var currentTranslateY = parseFloat(matrix[13] || matrix[5]);

			var newTranslateY = currentTranslateY - (scrollChange / speedFactor);
			newTranslateY = Math.min(Math.max(newTranslateY, -shift), 0);

			elem.find('.image-wrapper').css({ 'transform': 'translateY(' + newTranslateY + 'px)' });
		}
	}
	var previousScrollDistance = 0;

	$(window).scroll(function() {
		var viewport = $(window).scrollTop();
		var currentScrollDistance = $(window).scrollTop();
		var scrollChange = currentScrollDistance - previousScrollDistance;
		// console.log(scrollChange);
		previousScrollDistance = currentScrollDistance;

		$('.image-video-groupe figure.translate-shift, .image figure.translate-shift').each(function() {

			const shift = 120;
			const speedFactor = 8;

			if (isElementPartiallyInViewport($(this)[0])) {

				translateImages($(this), shift, speedFactor, scrollChange);
			}
		});
	});


	var resizeTimer;
	$(window).on('resize', function(e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {

			$('.image-video-groupe  .image-wrapper, .image .image-wrapper').each(function() {
				$(this).removeAttr('style');
			});

			$('.image-video-groupe figure.translate-shift img, .image figure.translate-shift img').each(function() {
				shrinkImage($(this));
			});

		}, 150);
	});

	// ------------------------------------------------------------------------------------- 
	// smooth scrolling
	$('a[href^="#"]').on('click', function(e) {
		e.preventDefault();
		var target = this.hash;
		var $target = $(target);
		$('html, body').stop().animate({
			'scrollTop': $target.offset().top - 40
		}, 900, 'swing', function() {
			window.location.hash = target;
		});
	});
	// -------------------------------------------------------------------------------------



	//-------------------------------------------------------------------------------------------------------------------------
	// all resize functions
	//-------------------------------------------------------------------------------------------------------------------------

	var resizeTimer;
	$(window).on('resize', function(e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			classMobile();
			headerReset()
		}, 150);
	});

	// ------------------------------------------------------------------

})(jQuery);





// You can also pass an optional settings object
// below listed default settings

// 	// Global settings:
// 	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
// 	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
// 	initClassName: 'aos-init', // class applied after initialization
// 	animatedClassName: 'aos-animate', // class applied on animation
// 	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
// 	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
// 	debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
// 	throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
// 
// 
// 	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
// 	offset: 120, // offset (in px) from the original trigger point
// 	delay: 0, // values from 0 to 3000, with step 50ms
// 	duration: 400, // values from 0 to 3000, with step 50ms
// 	easing: 'ease', // default easing for AOS animations
// 	once: false, // whether animation should happen only once - while scrolling down
// 	mirror: false, // whether elements should animate out while scrolling past them
// 	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// 
// });